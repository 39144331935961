import { makeAutoObservable } from "mobx";
import { AuthStore } from "./auth/auth.store";
import { AuthStoreImpl } from "./auth/auth.store.impl";
import { ChatStore } from "./chat/chat.store";
import { ChatStoreImpl } from "./chat/chat.store.impl";
import { ConfigStoreImpl } from "./config/config.store.impl";
import { ConfigStore } from "./config/config.store";

export class Stores {
  /**
   * Contains authentication info such as userId, tokens, chainID etc.
   */
  authStore: AuthStore = new AuthStoreImpl();

  /**
   * Contains chat specific data such as channels
   */
  chatStore: ChatStore = new ChatStoreImpl();

  /**
   * Configs related to app and responsiveness
   */
  configStore: ConfigStore = new ConfigStoreImpl();

  constructor() {
    makeAutoObservable(this);
  }
}

export const stores = new Stores();
