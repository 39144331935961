import { useCallback, useEffect, useState } from "react";
import { useChatContext } from "stream-chat-react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

// import { getWalletfromLensHandle } from "../../lib/utils";
import { accountIdFromUserId } from "@hashchat/js-hashchat";

import "./CreateChannel.css";

import { UserList } from "./UserList";

import { CloseCreateChannel } from "../../assets";

import type { ChannelFilters } from "stream-chat";
import { AccountId } from "caip";
import { useServices, useStores } from "../../hooks";
import { ethers } from "ethers";
import SearchLensProfiles from "./SearchLensProfiles";
import { LensProfileList } from "../../models";

type InputProps = {
  channelName: string;
  setChannelName: (value: React.SetStateAction<string>) => void;
};

const ChannelNameInput: React.FC<InputProps> = (props) => {
  const { channelName = "", setChannelName } = props;
  const handleChange = (event: {
    preventDefault: () => void;
    target: { value: string };
  }) => {
    event.preventDefault();
    setChannelName(event.target.value);
  };

  return (
    <div className="channel-name-input__wrapper">
      <p>Group Chat name (optional)</p>
      <input
        onChange={handleChange}
        placeholder="no-spaces"
        type="text"
        value={channelName}
      />
      <p></p>
    </div>
  );
};

type Props = {
  createType: string;
  filters: ChannelFilters[];
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateChannel: React.FC<Props> = (props) => {
  const { createType, filters, setIsCreating } = props;

  const { authService, chatService, ensService } = useServices();
  const { client, setActiveChannel } = useChatContext();
  const { chatStore, authStore } = useStores();

  const [channelName, setChannelName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<string[] | undefined>([]);
  const [isCreating, setCreating] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lensHandle, setlensHandle] = useState("");

  const createDMChannel = useCallback(async () => {
    setCreating(true);
    setWalletAddress(walletAddress);

    try {
      const senderAccountId = authStore.authSession!.accountId;
      const checksumAddress = ethers.utils.getAddress(walletAddress);
      const receiverAccountId = new AccountId({
        chainId: {
          namespace: "eip155",
          reference: "1", // note force all new DMs onto mainnet
        },
        address: checksumAddress,
      });

      await authService.upsertUser(receiverAccountId);

      const newChannelId = await chatService.createDM([
        senderAccountId,
        receiverAccountId,
      ]);
      if (!newChannelId.data) {
        throw newChannelId.error;
      }

      const channels = await client.queryChannels({
        id: { $eq: newChannelId.data },
      });

      setChannelName("");
      setIsCreating(false);
      setSelectedUsers([client.userID || ""]);
      setActiveChannel(channels[0]);
      chatStore.hideWelcomeScreen();
    } catch (err) {
      console.log(err);
    }
  }, [
    authService,
    authStore.authSession,
    chatService,
    chatStore,
    client,
    setActiveChannel,
    setIsCreating,
    walletAddress,
  ]);

  const createChannel = useCallback(
    async (event?: { preventDefault: () => void }) => {
      event?.preventDefault();
      if (!selectedUsers?.length) return;

      try {
        const senderAccountId = authStore.authSession!.accountId;
        const selectedUsersAccountIds = selectedUsers.map(accountIdFromUserId);
        await Promise.all(
          selectedUsersAccountIds.map(async (v) => {
            await authService.upsertUser(v);
          })
        );
        const newChannelId = await chatService.createDM([
          ...selectedUsersAccountIds,
          senderAccountId,
        ]);
        if (!newChannelId.data) {
          throw newChannelId.error;
        }

        const channels = await client.queryChannels({
          id: { $eq: newChannelId.data },
        });

        setChannelName("");
        setIsCreating(false);
        setSelectedUsers([client.userID || ""]);
        setActiveChannel(channels[0]);
        chatStore.hideWelcomeScreen();
      } catch (err) {
        console.log(err);
      }
    },
    [
      authService,
      authStore.authSession,
      chatService,
      chatStore,
      client,
      selectedUsers,
      setActiveChannel,
      setIsCreating,
    ]
  );

  useEffect(() => {
    console.log("selectedUsers: ", selectedUsers);
    console.log("walletAddress: ", walletAddress);
    if (selectedUsers && selectedUsers?.length > 1) {
      // createChannel();
    } else if (selectedUsers?.length) {
      // createDMChannel();
    }
  }, [createChannel, createDMChannel, selectedUsers, walletAddress]);

  return (
    <div className="create-channel__container">
      <div className="scroll">
        <div className="create-channel__header">
          <p>
            {createType === "team"
              ? "Start a new Direct Message or Create a Group"
              : "Send a Direct Message"}
          </p>
          <CloseCreateChannel {...{ setIsCreating }} />
        </div>
        {createType === "team" ? (
          <ChannelNameInput {...{ channelName, setChannelName }} />
        ) : (
          ""
        )}
        <div className="to-address-container">
          <SearchLensProfiles
            onSubmit={(lensProfileList: LensProfileList) => {
              if (lensProfileList.hasItems()) {
                setWalletAddress(lensProfileList.first()?.walletId ?? "");
              }
              setSelectedUsers(lensProfileList.getStreamIds());
              createChannel();
            }}
          />

          <div className="wallet-msg-container">
            <div className="input-btn-container">
              <TextField
                sx={{
                  padding: "0px",
                  textAlign: "center",
                  justifyContent: "center",
                }}
                size="small"
                id="outlined-basic"
                label="Enter Wallet Address"
                variant="outlined"
                className="textfield"
                onChange={async (event) => {
                  if (event.target.value.includes('.eth')) {
                    try {
                      const _ensAddress = (
                        await ensService.resolveName(event.target.value)
                      ).data;
                      if (_ensAddress) {
                        setWalletAddress(_ensAddress);
                      }
                    } catch (e) {
                      event.target.value = e + "error finding address. try again"
                    }
                  } else {
                    setWalletAddress(event.target.value);
                  }
                  console.log(event.target.value);
                  console.log(walletAddress);
                }
                }
              />
              <Button
                sx={{
                  padding: "7px",
                }}
                className="to-address-btn"
                size="small"
                variant="contained"
                onClick={createDMChannel}
                disabled={isCreating}
                style={{
                  backgroundColor: "#00bfff",
                  boxShadow: "none",
                }}
              >
                Enter
              </Button>
            </div>
          </div>
        </div>

        <div className="group-chat-title">
          Your Lens Follow Modules. Select users from your follow modules to create a
          group chat.
        </div>
        <UserList {...{ filters, setSelectedUsers }} />
      </div>
      <div className="create-channel__button-wrapper" onClick={createChannel}>
        <p>{createType === "team" ? "Create Chat" : "Create Message Group"}</p>
      </div>
    </div>
  );
};
