import React, { useState } from "react";
import {
  Channel,
  useChatContext,
} from "stream-chat-react";

import "./ChannelContainer.css";
import { ChannelInner } from "./ChannelInner";
import { ChannelEmptyState } from "../ChannelEmptyState/ChannelEmptyState";
import { CreateChannel } from "../CreateChannel/CreateChannel";
// eslint-disable-next-line
import { EditChannelName } from "../EditChannel/EditChannelName";
import { TeamMessageInput } from "../TeamMessageInput/TeamMessageInput";

import type { ChannelFilters, Message } from "stream-chat";

import { MessageType } from "@hashchat/js-hashchat";
import { EncryptedTeamMessage } from "../TeamMessage/EncryptedTeamMessage";
import { useServices, useStores } from "../../hooks";
// import { Channel } from "../../models";
import { WelcomeContainer } from "./containers/Welcome/WelcomeContainer";
import { observer } from "mobx-react-lite";
import { ChannelContainerProps } from "./ChannelContainerProps";
import { ThreadHeader } from "./containers/thread-header/thread-header.container";

export const ChannelContainer: React.FC<ChannelContainerProps> = observer(
  (props) => {
    const { createType, isCreating, isEditing, setIsCreating, setIsEditing } =
      props;
    // const { channel: _channel } = useChannelStateContext();
    const { chatService } = useServices();
    const {
      chatStore: { welcomeScreenVisible },
    } = useStores();
    
    // const activeChannel = useMemo(() => new ChannelModel(_channel), [_channel]);


    const [pinsOpen, setPinsOpen] = useState(false);
    const { channel } = useChatContext();

    if (isCreating) {
      const filters: ChannelFilters[] = [];
      return (
        <div className="channel__container">
          <CreateChannel {...{ createType, filters, setIsCreating }} />
        </div>
      );
    }

    /**
     * NOTE: Why channel list has 1 hidden channel on load?
     */
    if (welcomeScreenVisible) {
      return <WelcomeContainer />;
    }

    if (!channel) {
      return <></>;
    }

    if (isEditing) {
      const filters: ChannelFilters[] = [];

      if (channel.state.members) {
        const channelMembers = Object.keys(channel.state.members);

        if (channelMembers.length) {
          // @ts-expect-error
          filters.id = { $nin: channelMembers };
        }
      }

      return (
        <div className="channel__container">
          <EditChannelName {...{ filters, setIsEditing }} />
        </div>
      );
    }

    const sendMessage = async (channelId: string, message: Message) => {
      const response = await chatService.send(
        { type: MessageType.GET_STREAM, body: message },
        channelId
      );

      if (response.data) {
        return {
          message: response.data,
        } as any;
      }
    };

    return (
      <div className="channel__container">
        <div className="channels-wrapper"></div>
          <Channel
            EmptyStateIndicator={ChannelEmptyState}
            Input={TeamMessageInput}
            doSendMessageRequest={(channelId: string, message: Message) => {
              return sendMessage(channelId, message);
            }}
            Message={(messageProps) => (
              <EncryptedTeamMessage {...messageProps} {...{ setPinsOpen }} />
            )}
            ThreadHeader={(threadProps) => (
              <ThreadHeader {...threadProps} {...{ setPinsOpen }} />
            )}
            TypingIndicator={() => null}
          >
            <ChannelInner
              {...{
                pinsOpen,
                setIsEditing,
                setPinsOpen,
              }}
            />
          </Channel>
      </div>
    );
  }
);
