import { Avatar } from "stream-chat-react";
import client from '../apollo';
import { DefaultProfileRequest, DefaultProfileDocument } from "../generated/generated";
import { useEffect, useState } from "react";
import "./HCAvatar.css";
import { useServices } from "../hooks";
import React from "react";
import { profiles } from "../lib/get-profiles";

const getDefaultProfileRequest = async (request: DefaultProfileRequest) => {
    const result = await client.query({
        query: DefaultProfileDocument,
        variables: {
            request,
        },
    });

    // console.log(result);
    return result.data.defaultProfile;
};

function getURLfromIPFS(url: string): string {
    const IPFS_GATEWAY = "https://lens.infura-ipfs.io/ipfs/";
    return url.replace("ipfs://", IPFS_GATEWAY);
}

function walletFromUserId(userid: string) {
    // check to see if userid is a wallet or eip155 name.
    if (userid.startsWith("eip155")) {
        return userid.split("_").pop();
    } else if (userid.startsWith("0x")) {
        return userid;
    }
};

let HCAvatar: React.FC<{ userId: string, size: number }> = (props) => {
    const { userId, size } = props;
    const _userWallet = walletFromUserId(userId);
    const [userName, setUserName] = useState(_userWallet?.slice(0, 5) + "..." + _userWallet?.slice(-5));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [imgSrc, setImgSrc] = useState("/placeholder/avatar.png");
    const [isLens, setIsLens] = useState(false)
    const { ensService } = useServices();

    useEffect(() => {
        const getDefaultProfile = async () => {
            if (userId !== "") {
                const _walletAddress = walletFromUserId(userId);
                const result = await getDefaultProfileRequest({ ethereumAddress: _walletAddress });
                if (result) {
                    setIsLens(true)
                    setUserName(result.handle);
                    const profile = await profiles({
                        handles: result?.handle,
                    });
                    if (profile.profiles.items[0].picture.original.url){
                        // console.log('profiles: result', profile.profiles.items[0].picture.original.url);
                        setImgSrc(getURLfromIPFS(profile.profiles.items[0].picture.original.url));
                    }
                }
            }

        };
        getDefaultProfile();
    }, [userId]);




    useEffect(() => {
        const getENS = async () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _walletAddress = walletFromUserId(userId);
            if (!isLens) {
                try {
                    const ensName = (
                        await ensService.lookupAddress(_walletAddress || "")
                    ).data;

                    if (ensName) {
                        setUserName(ensName.toLocaleLowerCase());
                        console.log("ensName", ensName);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        };
        getENS();
    }, [ensService, isLens, userId]);



    // eslint-disable-next-line react/style-prop-object
    return <div className="avatar-container">
        <Avatar image={imgSrc} name={userName} size={size} />
        {userName}
    </div>
};



export default HCAvatar = React.memo(HCAvatar);
