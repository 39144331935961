import React, { useEffect, useState } from "react";
import { ChannelFilters, ChannelSort, StreamChat } from "stream-chat";
import {
  ChannelStateProvider,
  Chat,
  enTranslations,
  Streami18n,
  TypingProvider,
  useChatContext,
} from "stream-chat-react";

import "stream-chat-react/dist/css/index.css";
import "./App.css";

import { ChannelContainer } from "./components/ChannelContainer/ChannelContainer";
import { ChannelListContainer } from "./components/ChannelListContainer/ChannelListContainer";
import Logo from "./assets/img/logo.svg";
import { ServicesProvider, StoresProvider, useServices } from "./hooks";
import { stores } from "./stores";
import { ApiServiceImpl, ENV } from "./services";
import { AuthSession } from "@hashchat/js-hashchat";
import { WelcomeConnectContainer } from "./components/WelcomeConnect/WelcomeConnectContainer";

import "@rainbow-me/rainbowkit/dist/index.css";

import { darkTheme, getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import {
  allChains,
  chain,
  configureChains,
  createClient,
  useSigner,
  WagmiConfig,
} from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";
import { ApolloProvider } from "@apollo/client";
import client from "./apollo";
import { LensAuth } from "./lens/lens-auth";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { NoLens } from "./components/NoLens/NoLens";
import { Navbar } from "./components/Navbar/Navbar";
import { observer } from "mobx-react-lite";

import "./App.media-queries.css";

const { chains, provider } = configureChains(allChains, [
  infuraProvider({ apiKey: process.env.REACT_APP_INFURA_KEY }),
  publicProvider(),
]);

const { connectors } = getDefaultWallets({
  appName: "Hashchat",
  chains,
});

const wagmiClient = createClient({
  autoConnect: false,
  connectors: connectors(),
  provider,
});

const urlParams = new URLSearchParams(window.location.search);

const theme = urlParams.get("theme") || "light";
const i18nInstance = new Streami18n({
  language: "en",
  translationsForLanguage: {
    ...enTranslations,
  },
});

// const filters: ChannelFilters[] = [
//   { type: "team", demo: "team" },
//   { type: "messaging", demo: "team" },
// ];

const options = { state: true, watch: true, presence: true, limit: 20 };
const sort: ChannelSort = {
  last_message_at: -1,
  updated_at: -1,
};

const MainComponent = observer(() => {
  const { authService, chatService, ensService } = useServices();
  const { authStore } = stores;
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  const [createType, setCreateType] = useState("");

  const [isCreating, setIsCreating] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const signer = useSigner();

  useEffect(() => {
    const handleColorChange = (color: string) => {
      const root = document.documentElement;
      if (color.length && color.length === 7) {
        root.style.setProperty("--primary-color", `${color}E6`);
        root.style.setProperty("--primary-color-alpha", `${color}1A`);
      }
    };

    window.addEventListener("message", (event) =>
      handleColorChange(event.data)
    );

    return () => {
      window.removeEventListener("message", (event) =>
        handleColorChange(event.data)
      );
    };
  }, []);

  useEffect(() => {
    const login = async () => {
      if (!signer.data) return;

      let _authSession: AuthSession | null = authStore.authSession;
      if (!_authSession) {
        const response = await authService.connect(signer.data);
        _authSession = response.data ?? null;
        if (_authSession) {
          await chatService.authenticate();
          authStore.authSession = _authSession;
        } else {
          return;
        }
      }

      const apiKey =
        urlParams.get("apikey") || ApiServiceImpl.getEnv(ENV.STREAM_KEY);
      const client = StreamChat.getInstance(apiKey!, {
        enableInsights: true,
        enableWSFallback: true,
      });

      // Issue 44, 23 and 14 get their start here
      // This is where client and accountId come together
      // needed dedicated infura due to ENS is only on mainnet
      let user_name =
        _authSession.accountId.address.slice(0, 5) +
        "..." +
        _authSession.accountId.address.slice(-5);
      try {
        const ensName = (
          await ensService.lookupAddress(_authSession.accountId.address)
        ).data;

        if (ensName) {
          authStore.ensName = ensName;
          user_name = ensName.toLocaleLowerCase();
          console.log("ENS name: " + ensName);
        }
      } catch (e) {
        console.log(e);
      }

      authStore.userName = user_name;

      await client.connectUser(
        {
          id: chatService.userId!,
          name: user_name,
        },
        chatService.userToken!
      );

      // image: makeBlockie(accountId.address)
      setChatClient(client);
    };

    if (!chatClient) {
      login();
    }
  }, [authStore, chatClient, ensService, authService, chatService, signer]);

  if (!signer.data) return <WelcomeConnectContainer />;

  if (!chatClient || authStore.loadingLensAccount)
    return (
      <div className="welcome-screen">
        <img height={60} src={Logo} alt="logo" className="loader-logo" />
        <div>Loading...</div>
      </div>
    );

  // if (!authStore.lensAccount?.profile) {
  //   return <NoLens />;
  // }

  const filters: ChannelFilters[] = [
    { type: "team", members: { $in: [chatClient.userID!] } },
    { type: "messaging", members: { $in: [chatClient.userID!] } },
  ];

  return (
    <>
      {" "}
      <div className="navbar">
        <Navbar />
      </div>
      <div className="app__wrapper">
        <Chat client={chatClient} {...{ i18nInstance }} theme={`team ${theme}`}>
          <>
            <ChannelComponent
              {...{
                isCreating,
                filters,
                options,
                setCreateType,
                setIsCreating,
                setIsEditing,
                sort,
                createType,
                isEditing,
              }}
            />
          </>
        </Chat>
      </div>
    </>
  );
});

const ChannelComponent = observer(
  ({
    isCreating,
    filters,
    options,
    setCreateType,
    setIsCreating,
    setIsEditing,
    sort,
    createType,
    isEditing,
  }: any) => {
    const { channel } = useChatContext();

    return (
      <ChannelStateProvider
        value={{
          channel: channel!,
          suppressAutoscroll: true,
          channelCapabilities: {},
          channelConfig: undefined,
          multipleUploads: true,
          notifications: [],
        }}
      >
        <TypingProvider value={{}}>
          <>
            <ChannelListContainer
              {...{
                isCreating,
                filters,
                options,
                setCreateType,
                setIsCreating,
                setIsEditing,
                sort,
              }}
            />
            <ChannelContainer
              {...{
                createType,
                isCreating,
                isEditing,
                setIsCreating,
                setIsEditing,
              }}
            />
          </>
        </TypingProvider>
      </ChannelStateProvider>
    );
  }
);

const App = () => {
  return (
    <>
      <ServicesProvider>
        <StoresProvider>
          <WagmiConfig client={wagmiClient}>
            <ApolloProvider client={client}>
              <RainbowKitProvider modalSize="compact" chains={[chain.mainnet]}
                theme={darkTheme({
                  accentColor: '#619dee',
                  accentColorForeground: 'white',
                })}>
                <MainComponent />
                <LensAuth />
              </RainbowKitProvider>
            </ApolloProvider>
          </WagmiConfig>
        </StoresProvider>
      </ServicesProvider>
    </>
  );
};

export default App;
