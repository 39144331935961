import { EnsService } from "./ens/ens.service";
import { EnsServiceImpl } from "./ens/ens.service.impl";
import {
  Services as SDKServices,
  AuthService,
  ChatService,
  GnoSafeSenderService,
} from "@hashchat/js-hashchat";

export class Services {
  ensService: EnsService = new EnsServiceImpl();
  authService: AuthService;
  chatService: ChatService;
  safeSenderService: GnoSafeSenderService;

  constructor(chatServiceApiKey: string) {
    const sdkServices = new SDKServices(chatServiceApiKey);
    this.chatService = sdkServices.chatService;
    this.authService = sdkServices.authService;
    this.safeSenderService = sdkServices.safeSenderService;
  }
}

export const services = new Services(process.env.REACT_APP_STREAM_KEY!);
