import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import type { Channel, ChannelFilters } from "stream-chat";
import {
  ChannelList,
  ChannelListProps,
  useChatContext,
} from "stream-chat-react";

import { ChannelList as ChannelListModel } from "../../models";
import "./ChannelListContainer.css";
// import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { useStores } from "../../hooks";
import { TeamChannelList } from "../TeamChannelList/TeamChannelList";
import { TeamChannelPreview } from "../TeamChannelPreview/TeamChannelPreview";
import IconFooter from "../IconFooter";

type Props = Omit<ChannelListProps, "filters"> & {
  setCreateType: React.Dispatch<React.SetStateAction<string>>;
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  filters: ChannelFilters[];
};

// const customChannelTeamFilter = (
//   communities: Channel<DefaultStreamChatGenerics>[]
// ) => {
//   return communities.filter((channel) => channel.type === "team");
// };

const customChannelMessagingFilter = (channels: Channel[]) => {
  return channels.filter((channel) => channel.type === "messaging");
};

export const ChannelListContainer: React.FC<Props> = observer((props) => {
  const { filters, options, setCreateType, setIsCreating, setIsEditing, sort } =
    props;
  const { chatStore, configStore } = useStores();
  const { sidebarVisible } = configStore;
  const { channelList } = chatStore;
  const { client } = useChatContext();

  useEffect(() => {
    channelList.discardInvalidChannels();
  }, [channelList, channelList.id]);

  /**
   * TODO: There is an issue with re-rendering when an observable array is updated.
   * MobX is not re-rendering this component because channelList is not
   * directly rendered by this component.
   */
  const [refresh, setRefresh] = useState(false);

  const loadChannels = useCallback(async () => {
    if (client.userID == null) return;

    try {
      // eslint-disable-next-line
      const channels = await client.queryChannels(
        { members: { $in: [client.userID!] } },
        [],
        {
          watch: false,
          state: true,
        }
      );

      // Stores all channels to chat store
      channelList.replace(new ChannelListModel(channels));
      /**
       * Note: This is a temporary solution to rerender until
       * MobX array re-render issue is resolved
       */
      setRefresh(!refresh);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line
  }, [client]);

  useEffect(() => {
    // Refresh active channels if the user tries to join any new channel
    loadChannels();
  }, [client, loadChannels]);

  return (
    <div
      className={`channel-list__container ${
        sidebarVisible ? "fade-in" : "fade-out"
      }`}
    >
      <div className="channel-list__list__wrapper">
        {/*<ChannelSearch />*/}

        <ChannelList
          channelRenderFilterFn={customChannelMessagingFilter}
          filters={filters[1]}
          options={options}
          setActiveChannelOnMount={false}
          sort={sort}
          List={(listProps) => (
            <TeamChannelList
              {...listProps}
              {...{ setCreateType, setIsCreating, setIsEditing }}
              type="messaging"
            />
          )}
          Preview={(previewProps) => (
            <TeamChannelPreview
              {...previewProps}
              {...{ setIsCreating, setIsEditing }}
              type="messaging"
              loadChannels={loadChannels}
            />
          )}
        />
      </div>
      <div className="nav-close-wrapper">
        <i
          className="fa fa-times-circle"
          onClick={() => configStore.hideSidebar()}
        ></i>
      </div>
      <IconFooter />
    </div>
  );
});
