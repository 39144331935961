import {
  Avatar,
  useChannelActionContext,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";
import "./TeamChannelHeader.css";
import { ChannelInfo, PinIcon } from "../../assets";
import { useEffect, useRef, useState } from "react";
import { Channel } from "../../models";
import { ChainID } from "../../types";
import HCAvatar from "../HCAvatar";

type Props = {
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setPinsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TeamChannelHeader: React.FC<Props> = (props) => {
  const { channel: _channel, watcher_count } = useChannelStateContext();
  const channel = useRef(new Channel(_channel)).current;
  const { id: channelID } = channel;
  const { setIsEditing, setPinsOpen } = props;
  // const [isSafeTrue, setIsSafeTrue] = useState(false);
  // const [safeURL, setSafeURL] = useState("");
  // const [safeAddress, setSafeAddress] = useState("");
  const { client } = useChatContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [chainID, setChainID] = useState<string>();
  const { closeThread } = useChannelActionContext();
  const teamHeader = `# ${channel.name || channel?.dataId || "random"}`;
  // const [chatFocus, setChatFocus] = useState(true);

  const chains = useRef({
    [ChainID.MATIC]: "matic",
    [ChainID.ETH]: "eth",
    [ChainID.GNO]: "gno",
  }).current;

  useEffect(() => {
    if (channel.isSafe()) {
      // setIsSafeTrue(true);
      // string: "137" etc.
      const chainIDRef = channel.getChainId();

      if (chainIDRef) {
        // Is this correct?
        setChainID(chains[chainIDRef]);
      }

      // setSafeAddress(channel.getSafeAddress());
    }
  }, [channelID, chains, channel]);



  const getMessagingHeader = () => {
    const members = Object.values(channel.members).filter(
      ({ user }) => user?.id !== client.userID
    );
    const additionalMembers = members.length - 3;

    if (!members.length) {
      return (
        <div className="team-channel-header__name-wrapper">
          <Avatar image={null} size={32} />
          <p className="team-channel-header__name user">Hashchat Degen</p>
        </div>
      );
    }

    return (
      <div className="team-channel-header__name-wrapper">
        {members.map(({ user }, i) => {
          if (i > 2) return null;
          return (
            <div key={i} className="team-channel-header__name-multi">
              {/* <Avatar
                image={user?.image}
                name={user?.name || user?.id}
                size={32}
              />
              <p className="team-channel-header__name user">
                {user?.name || user?.id || "Hashchat Degen"}
              </p> */}
              <HCAvatar userId={user?.id || ""} size={32} />
            </div>
          );
        })}
        {additionalMembers > 0 && (
          <p className="team-channel-header__name user">{`and ${additionalMembers} more`}</p>
        )}
      </div>
    );
  };

  const getWatcherText = (watchers?: number) => {
    if (!watchers) return "No users online";
    if (watchers === 1) return "1 user online";
    return `${watchers} users online`;
  };

  return (
    <>
      <div className="team-channel-header__container">
        {channel.type === "messaging" ? (
          getMessagingHeader()
        ) : (
          <div className="team-channel-header__channel-wrapper">
            <p className="team-channel-header__name">{teamHeader}</p>
            <span
              style={{ display: "flex" }}
              onClick={() => setIsEditing(true)}
            >
              <ChannelInfo />
            </span>
          </div>
        )}
        <div className="team-channel-header__right">
          <p className="team-channel-header__right-text">
            {getWatcherText(watcher_count)}
          </p>
          <div
            className="team-channel-header__right-pin-wrapper"
            onClick={(event) => {
              if (closeThread) closeThread(event);
              setPinsOpen((prevState) => !prevState);
            }}
          >
            <PinIcon />
            <p className="team-channel-header__right-text">Pins</p>
          </div>
        </div>
      </div>
    </>
  );
};
