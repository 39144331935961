import { Channel as StreamChannel } from "stream-chat";
import { Entity } from "./core/entity";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { ChainID } from "../types";
import { ChannelSourceType } from "../types/chat";
import {
  MemberSort,
  QueryMembersOptions,
  UserFilters,
} from "stream-chat/src/types";
import { StreamChatGenerics } from "stream-chat-react/dist/stories/utils";

export class Channel extends Entity<StreamChannel<DefaultStreamChatGenerics>> {
  get cid(): string {
    return this.dto?.cid ?? "";
  }

  get name(): string {
    return this.dto?.data?.name ?? "";
  }

  get type(): string {
    return this.dto?.type ?? "";
  }

  get members() {
    return this.dto?.state.members ?? {};
  }

  get dataId(): string {
    return (this.dto?.data?.id as string) ?? "";
  }

  queryMembers(
    filterConditions: UserFilters<StreamChatGenerics>,
    sort: MemberSort<StreamChatGenerics> = [],
    options: QueryMembersOptions = {}
  ) {
    return this.dto.queryMembers(filterConditions, sort, options);
  }

  isDM(): boolean {
    return this.cid.startsWith("messaging");
  }

  isTeam(): boolean {
    return this.cid.startsWith("team");
  }

  validateCidAsDM(): boolean {
    return (
      this.isDM() && this.cid.includes("members") && this.cid.includes(":")
    );
  }

  validateCidAsPoap(): boolean {
    return this.isTeam() && this.isPoap() && this.cid.split("_").length === 4;
  }

  validateCidAsSafe(): boolean {
    return (
      this.isTeam() &&
      this.isSafe() &&
      this.cid.split("_").length === 4 &&
      this.cid.split("_")?.pop()?.length === 42 &&
      !!this.cid.split("_")?.pop()?.includes("0x")
    );
  }

  validateCidAsNft(): boolean {
    return (
      this.isTeam() &&
      this.isNFT() &&
      this.cid.split("_").length === 3 &&
      this.cid.split("_")?.pop()?.length === 42 &&
      !!this.cid.split("_")?.pop()?.includes("0x")
    );
  }

  /**
   * Ensures that the channel is not invalid
   * Note: Invalid channels with incorrect cid crashes the app
   * Hence they must be removed before rendering
   * TODO: Add support for tokens
   */
  isValid(): boolean {
    if (this.isDM()) {
      return this.validateCidAsDM();
    } else if (this.isNFT()) {
      return this.validateCidAsNft();
    } else if (this.isPoap()) {
      return this.validateCidAsPoap();
    } else if (this.isSafe()) {
      return this.validateCidAsSafe();
    } else {
      return false;
    }
  }

  /**
   * Returns true if the channel is related to gnosis safes
   */
  isSafe(): boolean {
    return (
      this.id.startsWith("gno_eip155_") || this.id.startsWith("safe_eip155_")
    );
  }

  isPoap(): boolean {
    return this.id.includes("poap_eip155_");
  }

  isToken(): boolean {
    return this.id.startsWith("eip155") && this.id.includes("erc20");
  }

  isNFT(): boolean {
    // TODO: Starting pattern of NFT id doesn't match with the rest.
    // Needs to be consistent
    return this.id.startsWith("eip155") && this.id.includes("erc721");
  }

  getSourceType(): ChannelSourceType {
    if (this.isPoap()) {
      return ChannelSourceType.POAP;
    }

    if (this.isSafe()) {
      return ChannelSourceType.SAFE;
    }

    if (this.isNFT()) {
      return ChannelSourceType.NFT;
    }

    return ChannelSourceType.DIRECT;
  }

  /**
   * Returns the id of asset from which this channel was created
   */
  getSourceId(): string {
    const sourceType = this.getSourceType();

    switch (sourceType) {
      default:
        return this.id;
    }
  }

  getChainId() {
    // Returns (for example): safe, eip155, 137, address
    const idArr = this.id.split("_");
    return idArr[2] as unknown as ChainID;
  }

  getSafeAddress(): string {
    // Returns (for example): safe, eip155, 137, address
    const idArr = this.id.split("_");
    return idArr[3] ?? "";
  }

  isMessaging(): boolean {
    return this.type === "messaging";
  }
}
